<template>
  <section id="customers-analytics">
    <b-overlay :show="showCustomerGroups" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Customer groups
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-customer-groups" />
          </h4>
          <b-popover target="popover-customer-groups" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportCustomerGroupsStart" spinner-variant="primary" variant="transparent" blur="3px"
              rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportCustomerGroupsTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="customerGroupsTable.currentPage"
          :per-page="10" :items="customerGroupsTable.items" :fields="customerGroupsTable.fields"
          :sort-by.sync="customerGroupsTable.sortBy" :sort-desc.sync="customerGroupsTable.sortDesc"
          :sort-direction="customerGroupsTable.sortDirection" :filter="customerGroupsTable.filter"
          :filter-included-fields="customerGroupsTable.filterOn" @row-clicked="customerGroupsRowClicked">
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="customerGroupsTable.currentPage" :total-rows="customerGroupsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="modal-customer-group-details" size="xl" :title="customerGroupName" cancel-title="Close"
        cancel-variant="outline-secondary" :no-close-on-backdrop="true" @shown="onCustomerGroupDetailsModalShown">
        <b-overlay :show="showProductsByCustomers" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Products by customers
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-products-customers" />
              </h4>
              <b-popover target="popover-products-customers" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
              <b-card-text class="font-medium-5 mb-0">
                <b-overlay :show="showExportProductsByCustomersStart" spinner-variant="primary" variant="transparent"
                  blur="3px" rounded="sm">
                  <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                    @click="exportProductsByCustomersTable" />
                </b-overlay>
              </b-card-text>
            </b-card-header>
            <b-table striped hover responsive class="position-relative"
              :current-page="productsByCustomersTable.currentPage" :items="productsByCustomersTable.items"
              :fields="productsByCustomersTable.fields" :sort-by.sync="productsByCustomersTable.sortBy"
              :sort-desc.sync="productsByCustomersTable.sortDesc"
              :sort-direction="productsByCustomersTable.sortDirection" :filter="productsByCustomersTable.filter"
              :filter-included-fields="productsByCustomersTable.filterOn"
              @sort-changed="productsByCustomersTableSortChanged" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="productsByCustomersTable.currentPage"
                  :total-rows="productsByCustomersTable.totalRows" first-number last-number prev-class="prev-item"
                  next-class="next-item" class="mb-0" @change="handleProductsByCustomersTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
        <b-row>
          <b-col xl="6">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Turnover by SKUs
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-turnover-skus" />
                </h4>
                <b-popover target="popover-turnover-skus" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <ECharts ref="topProductsByCustomersChart" :options="topProductsByCustomersChart"
                style="width: 100%; height: 400px;" />
            </b-card>
          </b-col>
          <b-col xl="6">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Turnover by categories
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-turnover-categories" />
                </h4>
                <b-popover target="popover-turnover-categories" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <ECharts ref="topCategoriesByCustomersChart" :options="topCategoriesByCustomersChart"
                style="width: 100%; height: 400px;" />
            </b-card>
          </b-col>
        </b-row>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BModal,
  BOverlay,
  BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BModal,
    BOverlay,
    BButton,
    ECharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showCustomerGroups: true,
      showProductsByCustomers: true,
      showTopsByCustomers: true,
      showExportCustomerGroupsStart: false,
      showExportProductsByCustomersStart: false,
      customerGroupID: '',
      customerGroupName: '',
      queryParamsCustomerGroups: {},
      queryParamsProductsByCustomers: {},
      productsByCustomersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total_turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty_sold', label: 'qty sold', sortable: true },
          {
            key: 'total_turnover',
            label: 'total turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      customerGroupsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          { key: 'total_customers', label: 'total customers', sortable: true },
          { key: 'total_orders', label: 'total orders', sortable: true },
          {
            key: 'avg_order_value',
            label: 'avg. order value',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'avg_order_number_per_customer', label: 'avg. order number per customer', sortable: true },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      topProductsByCustomersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      topCategoriesByCustomersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    try {
      await this.getCustomerGroups();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getCustomerGroups() {
      this.showCustomerGroups = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/industries/`, this.queryParamsCustomerGroups);
        this.customerGroupsTable.items = response.data;
        this.customerGroupsTable.totalRows = response.data.length * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showCustomerGroups = false;
      }
    },
    async getProductsByCustomers() {
      this.showProductsByCustomers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-by-customers/`, this.queryParamsProductsByCustomers);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productsByCustomersTable.items = results;
          this.productsByCustomersTable.totalRows = results[0].count * 2;
        }
        else {
          this.productsByCustomersTable.items = [];
          this.productsByCustomersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsByCustomers = false;
      }
    },
    async getTopsByCustomers() {
      this.showTopsByCustomers = true;
      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/top-products-by-customers/`, { industry: this.customerGroupName }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/top-categories-by-customers/`, { industry: this.customerGroupName }),
        ]);
        this.topProductsByCustomersChart.xAxis.data = response[0].data.results.map(item => item.sku);
        this.topProductsByCustomersChart.series = {
          name: "Amount",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: response[0].data.results.map(item => item.total_turnover),
        };
        this.topCategoriesByCustomersChart.xAxis.data = response[1].data.results.map(item => item.category);
        this.topCategoriesByCustomersChart.series = {
          name: "Amount",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: response[1].data.results.map(item => item.total_turnover),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showTopsByCustomers = false;
      }
    },
    async productsByCustomersTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsProductsByCustomers.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsProductsByCustomers.ordering = value.sortBy;
      }
      await this.getProductsByCustomers();
    },
    async handleProductsByCustomersTablePageChange(value) {
      this.queryParamsProductsByCustomers.page = value;
      await this.getProductsByCustomers();
    },
    customerGroupsRowClicked(row) {
      this.customerGroupID = row.id;
      this.customerGroupName = row.name;
      this.queryParamsProductsByCustomers.industry = this.customerGroupName;
      this.getProductsByCustomers();
      this.getTopsByCustomers();
      this.$refs['modal-customer-group-details'].show();
    },
    onCustomerGroupDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.topProductsByCustomersChart.resize();
        this.$refs.topCategoriesByCustomersChart.resize();
      });
    },
    async exportCustomerGroupsTable() {
      try {
        this.showExportCustomerGroupsStart = true;
        const exportedData = this.customerGroupsTable.items;
        this.showExportCustomerGroupsStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        const filename = `CustomerGroups.xlsx`;
        XLSX.writeFile(workbook, filename);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportProductsByCustomersTable() {
      try {
        this.showExportProductsByCustomersStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-products-by-customers/`, this.queryParamsProductsByCustomers);
        const exportedData = response.data.results;
        this.showExportProductsByCustomersStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        const filename = `${this.customerGroupName} products.xlsx`;
        XLSX.writeFile(workbook, filename);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>
